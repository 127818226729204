import React from "react"
import i18next from '../i18n/config';


import { Link, Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import ImgOurCoffeeBack from "../img/D_kawa_ziarnista_18/iko_powrot_nasze_kawy.png";

import ImgGroundCoffeeIcon1 from "../img/I_opakowania_kawy_mielonej_14/iko_saszetka.png";
import ImgGroundCoffeeIcon2 from "../img/I_opakowania_kawy_mielonej_14/iko_vacuum.png";
import ImgGroundCoffeeIcon3 from "../img/I_opakowania_kawy_mielonej_14/iko_torba.png";

import ImgGroundCoffeeBagMain from "../img/I_opakowania_kawy_mielonej_14/1_saszetki_kawa_mielona/torba_100g.png"
import ImgGroundCoffeeBagVacuum from "../img/I_opakowania_kawy_mielonej_14/2_vacuum_kawa_mielona/kostka_mielona@1x.png"
import ImgGroundCoffeeBagStand from "../img/I_opakowania_kawy_mielonej_14/3_torby_kawa_mielona/mielona_stojaca.png"



export default function Header(props) {



    return (
            <React.Fragment>
                <section id="ground-coffee-pack" class="ourcoffees firstSection">
                    <h1>{i18next.t('GroundCoffeePackagingH1')}</h1>
                    <p>{i18next.t('GroundCoffeePackagingP')}</p>

                    <div class="icons">
                        <a>
                            <div class="icon" onClick={() => document.scrolltosection("ground-bag")} >
                                <div><img loading="lazy" src={ImgGroundCoffeeIcon1} alt="Instanta Ground Bag with coffee" /></div>
                                <p>{i18next.t('GroundCoffeePackagingLink1')}</p>
                            </div>
                        </a>

                        <a>
                            <div class="icon" onClick={() => document.scrolltosection("ground-vacuum")} >
                                <div><img loading="lazy" src={ImgGroundCoffeeIcon2} alt="Instanta Vacuum Pack with coffee" /></div>
                                <p>{i18next.t('GroundCoffeePackagingLink2')}</p>
                            </div>
                        </a>
                        <a>
                            <div class="icon" onClick={() => document.scrolltosection("ground-stand")} >
                                <div><img loading="lazy" src={ImgGroundCoffeeIcon3} alt="Instanta Stand-up Bag with coffee" /></div>
                                <p>{i18next.t('GroundCoffeePackagingLink3')}</p>
                            </div>
                        </a>
                    </div>
                    <aside class="mininavi">
                        <div>
                            <Link to={'/OurPackaging/Packaging/'}>
                            <img loading="lazy" src={ImgOurCoffeeBack} alt="Instanta Coffee Back Icon" />
                            <p>{i18next.t('CapsulesMiniNaviBack')}</p>
                            </Link>
                        </div>
                    </aside>
                </section>

                <section id="ground-bag" class="freeze-dried main">
                    <div class="image"><img loading="lazy" src={ImgGroundCoffeeBagMain} loading="lazy" alt="Instanta Coffee Ground Bag" />
                    </div>
                    <div class="text">
                        <h2>{i18next.t('GroundCoffeePackagingLink1')}</h2>
                        <p>{i18next.t('GroundCoffeePackagingCoffeeBagP')}</p>

                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to={'/OurCoffees/GroundCoffee/'}>
                                    <p>{i18next.t('GroundCoffeePackagingMiniLink')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/Collaboration/'}>
                                    <p>{i18next.t('CapsulesLinkCollaboration')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/OurPackaging/Design/'}>
                                    <p>{i18next.t('CustomProductLink3')}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="ground-vacuum" class="powdered main">
                    <div class="image"><img loading="lazy" src={ImgGroundCoffeeBagVacuum} alt="Instanta Coffee Ground Vacuum Pack" />
                    </div>
                    <div class="text">
                        <h2>{i18next.t('GroundCoffeePackagingLink2')}</h2>
                        <p>{i18next.t('GroundCoffeePackagingVacuumPackP')}</p>

                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to={'/OurCoffees/GroundCoffee/'}>
                                    <p>{i18next.t('GroundCoffeePackagingMiniLink')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/Collaboration/'}>
                                    <p>{i18next.t('CapsulesLinkCollaboration')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/OurPackaging/Design/'}>
                                    <p>{i18next.t('CustomProductLink3')}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="ground-stand" class="granulated main">
                    <div class="image"><img loading="lazy" src={ImgGroundCoffeeBagStand} alt="Instanta Coffee Stand-Up Pack" /></div>

                    <div class="text">
                        <h2>{i18next.t('GroundCoffeePackagingLink3')}</h2>
                        <p>{i18next.t('GroundCoffeePackagingStandUpPackP')}</p>

                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to={'/OurCoffees/GroundCoffee/'}>
                                    <p>{i18next.t('GroundCoffeePackagingMiniLink')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/Collaboration/'}>
                                    <p>{i18next.t('CapsulesLinkCollaboration')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/OurPackaging/Design/'}>
                                    <p>{i18next.t('CustomProductLink3')}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>

            )


}